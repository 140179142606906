<template>
  <Card
    :titulo="
      'Agregar archivos carpeta: ' +
        $store.state.buscador.carpeta_seleccionada.nombre_carpeta
    "
    @activar="$emit('cerrar')"
  >
    <template v-slot:contenido>
      <v-row class="mt-5">
        <v-select
          v-model="tipo_upload"
          label="Tipo de digitalizacion"
          filled
          rounded
          dense
          :items="['Escaner', 'Subir archivos']"
          :append-outer-icon="tipo_upload == 'Escaner' ? 'mdi-reload' : null"
          @click:append-outer="getImages()"
        >
        </v-select>
      </v-row>
      <v-row>
        <v-col>
          <v-sheet v-if="tipo_upload == 'Escaner'">
            <Carousel
              @move_image="set_index_file_to_move($event)"
              @eliminar="eliminar_archivo($event)"
              :imagenes.sync="imagenes"
              @save_image_edited="save_image_edited($event)"
              @upload_images="upload_images($event)"
            />
            <v-btn
              style="color:white"
              color="indigo"
              block
              :disabled="imagenes.length == 0"
              @click="terminarProcesoArchivador()"
            >
              Guardar imagenes
            </v-btn>
          </v-sheet>
          <v-sheet v-else-if="tipo_upload == 'Subir archivos'">
            <Drop :permitir="'nop'" v-on:info="pruebaDrop" />
          </v-sheet>
          <v-dialog v-model="dialog_move_file" max-width="400">
            <v-card>
              <v-card-title>
                Moveras la imagen en la posición: {{ index_to_move.old + 1 }}
              </v-card-title>
              <v-card-title>
                A la posición: {{ index_to_move.new }}
              </v-card-title>
              <v-card-text>
                <v-text-field
                  label="Indica la posición del traslado"
                  v-model="index_to_move.new"
                >
                </v-text-field>
              </v-card-text>
              <v-card-actions>
                <v-btn dark @click="move_file()" block color="indigo darken-4">
                  Mover imagen
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </template>
  </Card>
</template>
<script>
import Carousel from "../celulas/Carousel.vue";
import Drop from "../Drop.vue";
const axios = require("axios").default;
import folders from "../../mixins/folders";
export default {
  mixins: [folders],
  components: {
    Carousel,
    Drop,
  },
  data() {
    return {
      datos_archivos: [],
      imagenes: [],

      files: [],
      indice: 1,
      ws: null,
      tipo_upload: null,
      position_to_insert: -1,
      index_to_move: {
        old: 0,
        new: 0,
      },
      dialog_move_file: false,

      imagenes_pdf: 1,
    };
  },
  watch: {
    tipo_upload(val) {
      console.log(val);
      if (val == "Escaner") {
        this.getImages();
      }
    },
  },
  created() {
    let _this = this;
    const that = this;
    console.log("Holsssi");
    window.URL = window.URL || window.webkitURL;
    var wsImpl = window.WebSocket || window.MozWebSocket;

    this.ws = new wsImpl("ws://localhost:8181/");

    this.ws.onmessage = function(e) {
      console.log(e);
      try {
        if (e.data instanceof Blob) {
          //-----Estas son las funciones que debe ejecutar tu input----//
          that.seleccionarFoto(e.data);

          that.addFiles(e.data, that.$store.state.token, that.indice, "blob");
          //---------------------------------------------------------//
        }
      } catch (error) {
        console.log(error);
      }
    };

    this.ws.onopen = function() {
      console.log("Open ");
      console.log(that.indice);
      //btn_scan.removeAttribute('disabled');
      //download_app.style.display = 'none';
    };
    this.$store.commit("crearToken", 25);
    this.ws.onerror = function(e) {
      console.log(e);
      console.log("Error");
      _this.$store.commit(
        "error",
        "El programa SCAN APP no ha sido activado. Por favor inicielo en el escritorio e ingrese nuevamente a esta vista si desea ESCANEAR archivos, si ese no es el caso ignore este mensaje"
      );
      //btn_scan.setAttribute('disabled', '');
      //download_app.style.display = '';
    };
  },
  methods: {
    eliminar_archivo(indice) {
      this.imagenes.splice(indice, 1);
      this.datos_archivos.splice(indice, 1);
      this.files.splice(indice, 1);
    },
    async getImages() {
      console.log("xd");
      this.ws.send("1100");
      //this.scannerInicio = true;
    },
    async pruebaDrop(event) {
      console.log("Funcion prueba Drop");
      console.log(event);
      console.log(event.archivo);
      //this.scannerInicio = true;

      for (let index = 0; index < event.archivo.length; index++) {
        await this.addFiles(
          event.archivo[index],
          this.$store.state.token,
          event.obj[index].nombre,
          event.obj[index].tipo
        );
      }

      /* Eduard V*/
      var reader = new FileReader();
      reader.readAsBinaryString(event.archivo[0]);
      reader.onloadend = () => {
        this.imagenes_pdf = reader.result.match(
          /\/Type[\s*]*\/Page[^s]/g
        ).length;
        console.log("Number of Pages:", this.imagenes_pdf);
      };

      this.terminarProcesoArchivador();
      //Comentarios
      // this.archivo2 = event.archivo
      // this.objUpload2 = event.obj
    },
    seleccionarFoto(event) {
      console.log(event);
      //event.setAttribute("style", "transform: rotate(" + 90 + "deg)");
      var reader = new FileReader();

      reader.onload = async (e) => {
        // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
        // Read image as base64 and set to imageData

        var result = e.target.result;

        if (this.position_initial == -1) {
          this.imagenes.push(result);
        } else {
          const images = await this.insert_element_at_postition(
            this.imagenes,
            this.position_initial,
            result
          );
          this.imagenes = [...images];
        }
      };

      reader.readAsDataURL(event);
    },
    async addFiles(blob, token, index, tipo) {
      //Se agrego un cuarto parametro llamado tipo el cual contendra
      //El tipo de archivo que se esta agregando .zip png jpg blob etc etc etc.
      //Con el fin de agregarlo al endpoint de almacenamiento y saber como debe hacerse la estructura de las url
      //Que permiten descargar los archivos la cual seria: http://url-endpoint/token/nombre.tipo
      let file = new FormData();
      console.log(token);
      file.append("file", blob);
      file.append("texto", token);
      file.append("nombre", index);

      console.log(blob);

      let obj = {
        nombre: index,
        token: token,
        tipo: tipo,
        autor: this.$store.state.dataUser.dataUser.nombre,
        sector: this.$store.state.dataUser.dataUser.sector,
        nombre_sector: this.$store.state.dataUser.dataUser.nombre_sector,
        //fecha_adjuntado: Date.parse(new Date()), // Indicara la fecha cuando se adjunto la imagen
        //meta_datos: [],
        estado_imagen: "habilitada",
      };

      obj = JSON.stringify(obj);
      if (this.position_to_insert == -1) {
        this.datos_archivos.push(obj);
        this.files.push(file);
        //this.files_format_blob.push(blob);
      } else {
        const datos_archivos = await this.insert_element_at_postition(
          this.datos_archivos,
          this.position_to_insert,
          obj
        );
        const files = await this.insert_element_at_postition(
          this.files,
          this.position_to_insert,
          obj
        );
        //const files_format_blob = await this.insert_element_at_postition(this.files_format_blob,this.position_to_insert,obj);
        this.datos_archivos = [...datos_archivos];
        this.files = [...files];
        //this.files_format_blob = [...files_format_blob];

        console.log(datos_archivos);
        console.log(files);
      }

      this.indice++;
    },
    async uploadImg() {
      //var token = 'TokenPrueba2'
      this.$store.state.load = true;

      //Activar un spinner load

      for (let index = 0; index < this.files.length; index++) {
        try {
          this.contadorImagensSubidas++;
          this.$store.commit("auth");

          if (index + 1 == 1) {
            //Upload docu crea la carpeta y anexa la primera imagen
            var responseUploadImg = await axios.post(
              "https://drive.educandote.co/audidoc/uploadDocu.php",
              this.files[index],
              {
                header: {
                  "Content-Type": "multipart/form-data",
                  Authorization: this.$store.state.header,
                },
              }
            );
            console.log("Upload Imagen 1", responseUploadImg);
          } else {
            //upgradeFolder.php anexa, datos al folder asociado al token

            var response = await axios.post(
              "https://drive.educandote.co/audidoc/upgradeFolder.php",
              this.files[index],
              {
                header: {
                  "Content-Type": "multipart/form-data",
                  Authorization: this.$store.state.header,
                },
              }
            );

            console.log("Upload Imagen " + index);
            console.log(response);
          }
        } catch (error) {
          //Posteriormente indicar cual sera el error en un dialog
          console.log(error);
          this.$store.state.load = false;
          return false; //Se retorna falso cuando existe un error en la subida de alguna imagen
        }
      }

      return true;
    },
    async set_pdf(body, collections) {
      try {
        const pdf = await this.$store.dispatch("llamado_post", {
          url: `https://docutest.educandote.co/types/collections/IMAGENES_${collections}/documents/import?action=create`,
          body: body,
          tipo_header: "indexador",
        });

        console.log(pdf);
      } catch (error) {
        console.log(error);
      }
    },
    async set_images(body, collections) {
      try {
        const imagenes = await this.$store.dispatch("llamado_post", {
          url: `https://docutest.educandote.co/types/collections/IMAGENES_${collections}/documents/import?action=create`,
          body: body,
          tipo_header: "indexador",
        });
        console.log(imagenes);
      } catch (error) {
        console.log(error);
      }
    },
    async get_ids_folder(token, collections) {
      let result = {
        id_images: [],
        id_pdf: "",
      };

      try {
        console.log(token);
        const response = await this.$store.dispatch("llamado_get", {
          url: `https://docutest.educandote.co/types/collections/IMAGENES_${collections}/documents/search?q=${token}&query_by=${"token"}&filter_by=token:[${token}]&per_page=${250}&sort_by=order:asc`,
          tipo_header: "indexador",
        });

        for (let index = 0; index < response.data.hits.length; index++) {
          if (response.data.hits[index].document.tipo == "pdf") {
            result.id_pdf = response.data.hits[index].document.id;
          } else {
            result.id_images.push(response.data.hits[index].document.id);
          }
        }
        console.log(result);

        //console.log(ids);
      } catch (error) {
        console.log(error);
      }
      return result;
    },
    async contarPaginasPDF(folder, archivo) {
      var formData = new FormData();
      formData.append("texto", folder);
      formData.append("nombre", archivo);
      const config = {
        url: "https://drive.educandote.co/audidoc/countPDF.php",
        method: "post",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      try {
        const { data } = await axios(config);

        return data.message == "" ? "0" : data.message;
      } catch (error) {
        // return error;
        return "0";
      }
    },
    async terminarProcesoArchivador() {
      if (this.imagenes.length > 0 || this.datos_archivos.length > 0) {
        this.$store.state.dialogCargando = true;
        var subidaImagenes = await this.uploadImg(); //Este upload permite subir todo ahora
        /* Eduard Version*/
        console.log("EV /buscador/AgregarArchivos");
        var cantidad_imagenes_pdf = this.imagenes_pdf;

        if (subidaImagenes) {
          console.log("Terminando desde archivador");
          try {
            //let body = "";
            for (let index = 0; index < this.datos_archivos.length; index++) {
              let info_file = JSON.parse(this.datos_archivos[index]);
              if (info_file.tipo == "pdf") {
                try {
                  let PDFPaginas = await this.contarPaginasPDF(
                    info_file.token,
                    `${info_file.nombre}.pdf`
                  );
                  console.log("Cantidad de paginas: ", PDFPaginas);
                  info_file.imagenes_por_archivo = PDFPaginas;
                } catch (error) {
                  info_file.imagenes_por_archivo = cantidad_imagenes_pdf;
                }
              } else {
                info_file.imagenes_por_archivo = "1";
              }
              // body =
              //   body +
              //   `{"id_busqueda": "${
              //     this.$store.state.buscador.archivo_seleccionado.id
              //   }", "id_carpeta": "${
              //     this.$store.state.buscador.carpeta_seleccionada.id
              //   }", "autor": "${info_file.autor}", "estado_imagen": "${
              //     info_file.estado_imagen
              //   }", "meta_datos": "", "nombre": "${
              //     info_file.nombre
              //   }", "nombre_sector": "${info_file.nombre_sector}", "sector": "${
              //     info_file.sector
              //   }", "tipo": "${info_file.tipo}", "token": "${
              //     info_file.token
              //   }", "fecha_creacion_int": ${Date.parse(
              //     new Date()
              //   )}, "order": ${Date.parse(
              //     new Date()
              //   )}, "extended_properties": "{}"}\n`;

              /* Eduard Version*/
              let body_pdf = `{"id_busqueda": "${
                this.$store.state.buscador.archivo_seleccionado.id
              }", "id_carpeta": "${
                this.$store.state.buscador.carpeta_seleccionada.id
              }", "autor": "${info_file.autor}", "estado_imagen": "${
                info_file.estado_imagen
              }", "meta_datos": "", "nombre": "${
                info_file.nombre
              }", "nombre_sector": "${info_file.nombre_sector}", "sector": "${
                info_file.sector
              }", "tipo": "${info_file.tipo}", "token": "${
                info_file.token
              }", "fecha_creacion_int": ${Date.parse(
                new Date()
              )}, "order": ${Date.parse(new Date()) +
                index *
                  250}, "extended_properties": "{}","imagenes_por_archivo": "${
                info_file.imagenes_por_archivo
              }"}\n`;

              await this.set_pdf(
                body_pdf,
                this.$store.state.buscador.coleccion_archivo_seleccionado
              );

              let body_imagenes = "";
              let name_images = [];
              for (let index = 0; index < cantidad_imagenes_pdf; index++) {
                body_imagenes =
                  body_imagenes +
                  `{"id_busqueda": "${
                    this.$store.state.buscador.archivo_seleccionado.id
                  }", "id_carpeta": "${
                    this.$store.state.buscador.carpeta_seleccionada.id
                  }", "autor": "${
                    info_file.autor
                  }", "estado_imagen": "${"INHABILITADA"}", "meta_datos": "", "nombre": "${index +
                    1}", "nombre_sector": "${
                    info_file.nombre_sector
                  }", "sector": "${
                    info_file.sector
                  }", "tipo": "${"png"}", "token": "${
                    info_file.token
                  }", "fecha_creacion_int": ${Date.parse(
                    new Date()
                  )}, "order": ${Date.parse(new Date()) +
                    index *
                      250}, "extended_properties": "{}","imagenes_por_archivo": "${
                    info_file.imagenes_por_archivo
                  }"}` +
                  "\n";
                name_images.push(index + 1);
              }

              await this.set_images(
                body_imagenes,
                this.$store.state.buscador.coleccion_archivo_seleccionado
              );

              // const result = await this.get_ids_folder(
              //   info_file.token,
              //   this.$store.state.buscador.coleccion_archivo_seleccionado
              // );

              // const archivos = await this.$store.dispatch("llamado_post", {
              //   url: `https://docutest.educandote.co/parse/classes/IMAGENESV3_${this.$store.state.buscador.coleccion_archivo_seleccionado}`,
              //   body: {
              //     id_images: result.id_images,
              //     id_pdf: result.id_pdf,
              //     folder: info_file.token,
              //     method: "PATCH",
              //     url_typesense: `https://docutest.educandote.co/types/collections/IMAGENES_${this.$store.state.buscador.coleccion_archivo_seleccionado}/documents/`,
              //     date: Date.parse(new Date()),
              //     body_for_enable: {
              //       estado_imagen: "habilitada",
              //     },
              //     body_for_disable: {
              //       estado_imagen: "INHABILITADA",
              //     },
              //     name_images: name_images,
              //     name_pdf: info_file.nombre,
              //   },
              //   tipo_header: "parse",
              // });
              // console.log(archivos);
              /* Eduard Version termina aca*/
            }

            // Esto Va fuera del for, es la vieja version
            // const imagenes = await this.$store.dispatch("llamado_post", {
            //   url: `https://docutest.educandote.co/types/collections/IMAGENES_${this.$store.state.buscador.coleccion_archivo_seleccionado}/documents/import?action=create`,
            //   body: body,
            //   tipo_header: "indexador",
            // });

            // console.log(imagenes);

            this.$emit("cerrar");

            this.$store.commit(
              "SET_CANTIDAD_DOCUMENTOS_SUBIDOS",
              this.imagenes.length
            );
            this.imagenes = [];
            this.files = [];
            this.indice = 0;
            this.datos_archivos = [];
            this.contadorImagensSubidas = 0;

            this.$store.commit(
              "exitoso",
              "Los archivos han sido agregados exitosamente"
            );
            this.$store.state.load = false;
            this.$store.commit("crearToken", 25);
          } catch (error) {
            this.$store.commit(
              "error",
              "Ha ocurrido un error inesperado en el momento de almacenar los datos en la coleccion " +
                error.message
            );
            console.log(error);
          }
        }
      } else {
        this.$store.commit(
          "error",
          "Por favor escanea algunas imagenes antes de enviar los datos"
        );
      }
      this.$store.state.dialogCargando = false;
    },
    upload_images(index_initial) {
      try {
        console.log("Por qué se llama por aca esto");
        console.log(index_initial);

        this.position_initial = index_initial;
        this.getImages();
      } catch (error) {
        console.log(error);
      }
    },
    save_image_edited(file) {
      try {
        console.log(file);
        let file_formdata = new FormData();
        file_formdata.append("file", file.image);
        file_formdata.append("texto", this.datos_archivos[file.index].token);
        file_formdata.append("nombre", this.datos_archivos[file.index].nombre);
        this.files[file.index] = file_formdata;
        var reader = new FileReader();
        reader.onload = async (e) => {
          // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
          // Read image as base64 and set to imageData

          var result = e.target.result;
          let imagenes = this.insert_element_at_postition(
            this.imagenes,
            file.index,
            result
          );
          imagenes.splice(file.index + 1, 1);
          console.log(imagenes);
          this.imagenes = [...imagenes];
        };

        reader.readAsDataURL(file.image);

        //this.imagenes[file.index] = file.image;
      } catch (error) {
        console.log(error);
      }
    },
    set_index_file_to_move(index) {
      console.log(index);
      this.index_to_move.old = index;

      this.dialog_move_file = true;
    },
    insert_element_at_postition(old_array, index, element) {
      try {
        console.log(old_array);
        old_array.splice(index, 0, element);
        return old_array;
      } catch (error) {
        console.log(error);
      }
    },

    async move_file() {
      try {
        const index = this.index_to_move.new - 1;
        const new_array = {
          imagenes: await this.insert_element_at_postition(
            this.imagenes,
            index,
            this.imagenes[this.index_to_move.old]
          ),
          datos_archivos: await this.insert_element_at_postition(
            this.datos_archivos,
            index,
            this.datos_archivos[this.index_to_move.old]
          ),
          files: await this.insert_element_at_postition(
            this.files,
            index,
            this.files[this.index_to_move.old]
          ),
        };

        console.log(new_array);
        this.eliminar_archivo(this.index_to_move.old);
        this.dialog_move_file = false;
        this.$store.commit(
          "exitoso",
          "La imagen ha sivo movida de forma exitosa"
        );
      } catch (error) {
        console.log(error);
        this.$store.commit(
          "error",
          "Algo ha salido mal moviendo la imagen " + error.message
        );
      }
    },
  },
};
</script>
