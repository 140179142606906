<template>
    <v-app>
        <v-main>
            <Buscador :field_tipos_documentales="'backup_tipos_documentales'" />
        </v-main>
    </v-app>
</template>
<script>
import Buscador from '../components/Buscador.vue'
export default {
    components: {
        Buscador
    },
    data(){
        return {

        }
    },
    created(){
        console.log('xdsssssssssssssssssssssssss')
    }
}
</script>