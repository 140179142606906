<template>
    <v-sheet>
        <v-row>
                <v-col cols="12" sm="3">
                    <Toolbar 
                    :titulo="'Carpetas'" 
                    :activar_tool="true" 
                    :accion="'Crear carpeta'" 
                    :icono="'mdi-folder-multiple-plus-outline'"
                    @activar="dialogo_crear_carpeta=true" 
                />
                <v-text-field
                    v-model="busar_carpeta"
                    filled rounded dense
                    label="Buscar expediente"
                ></v-text-field>
                <v-virtual-scroll
                    :bench="0"
                    :items="carpeta_filtradas"
                    height="650"
                    item-height="64"
                >
                    <template v-slot:default="{ item }">
                            <v-list-item v-if="item.estado_carpeta=='ACTIVO' || item.estado_carpeta == 'habilitada'"  @click="get_archivos_carpeta_seleccionada(item)">
                                <v-list-item-avatar>
                                    <v-icon :color="color(item)" class="grey lighten-1" dark >mdi-folder</v-icon>
                                </v-list-item-avatar>

                                <v-list-item-content>
                                    <v-list-item-title v-text="item.nombre_carpeta"></v-list-item-title>

                                </v-list-item-content>

                                <v-list-item-action>
                                <v-btn icon>
                                    <v-icon color="green lighten-1">mdi-information</v-icon>
                                </v-btn>
                                </v-list-item-action>
                            </v-list-item>
                            <v-list-item v-else>
                                <v-list-item-avatar>
                                    <v-icon :color="'red'" dark >mdi-folder</v-icon>
                                </v-list-item-avatar>

                                <v-list-item-content>
                                    <v-list-item-title v-text="'Eliminada '+item.nombre_carpeta"></v-list-item-title>

                                </v-list-item-content>

                                <v-list-item-action>
                                <v-btn icon>
                                    <v-icon color="red lighten-1">mdi-information</v-icon>
                                </v-btn>
                                </v-list-item-action>
                            </v-list-item>
                        <v-divider></v-divider>
                    </template>
                </v-virtual-scroll>
            </v-col>
            <v-col cols="12" sm="9">
                
                <ToolbarMultiIcons 
                    :titulo="'Archivos de: '+$store.state.buscador.carpeta_seleccionada.nombre_carpeta" 
                    :activar_tool="true"
                    
                    :actions="[{icon: 'mdi-file-document-multiple-outline', name: 'Agregar archivos', value:'upload_files'},
                                {icon: 'mdi-download', name: 'Descargar archivos', value:'download_files'},
                                {icon: 'mdi-pencil', name: 'Editar carpeta', value: 'edit_folder'},
                                {icon: 'mdi-delete', name: 'Eliminar carpeta', value: 'delete_folder'},
                                
                                
                                ]"
                    @upload_files="dialogo_agregar_archivos_carpeta=true"
                    @download_files="download_files(archivos)"
                    @edit_folder="dialogo_edit_folder=true"
                    @delete_folder="dialogo_delete_folder=true"

                />
                <ArchivosCarpetaSeleccionada :archivos="$store.state.buscador.archivos_carpeta_seleccionada" />
            </v-col>
        </v-row>
        
        
        <v-dialog v-model="dialogo_crear_carpeta" persistent max-width="600" >
            <Card :titulo="'Crear carpeta'" @activar="cerrar_dialogo_crear_carpeta()" >
                <template v-slot:contenido>
                    <v-form ref="form" v-model="valid">
                        <v-row>
                            <v-col>
                                <v-text-field :rules="[
                                        (v) => !!v || 'Es requerido asignar un nombre para la carpeta',
                                        ]" required 
                                    v-model="nueva_carpeta.nombre_carpeta" filled rounded dense label="Nombre carpeta"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            
                            <v-col>
                                <v-btn block @click="crear_carpeta(nueva_carpeta)" :disabled="!valid" style="color:white" color="indigo darken-4">Crear nueva carpeta </v-btn>
                            </v-col>
                            
                        </v-row>
                    </v-form>
                </template>
            </Card>
        </v-dialog>
        <v-dialog v-model="dialogo_edit_folder" max-width="500">
            <Card :titulo="'Editar carpeta '+$store.state.buscador.carpeta_seleccionada.nombre_carpeta" @activar="dialogo_edit_folder=false" >
                <template v-slot:contenido>
                    
                    <v-card-text>
                        <v-text-field filled rounded dense label="Nombre de la carpeta" v-model="name_folder" ></v-text-field>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn @click="editar_carpeta()" 
                                block dark 
                                color="green"
                        > 
                            Editar <v-icon> mdi-pencil </v-icon>
                        </v-btn>
                    </v-card-actions>
                </template>
            </Card>
        </v-dialog>
        <v-dialog v-model="dialogo_delete_folder" max-width="500" >
            <Card :titulo="'Eliminar carpeta'" :color="'red'" @activar="dialogo_delete_folder=false" >
                <template v-slot:contenido  >
                    <v-divider class="my-3"></v-divider>
                    <h2 style="text-align:center"> Estás seguro que deseas eliminar la carpeta: {{$store.state.buscador.carpeta_seleccionada.nombre_carpeta}} </h2>
                    <v-divider class="my-3"></v-divider>
                    <v-card-actions>
                        <v-btn block color="red" dark @click="delete_folder()" > Eliminar <v-icon>mdi-delete</v-icon> </v-btn>
                    </v-card-actions>
                </template>
            </Card>
        </v-dialog>
        <v-dialog v-model="dialogo_agregar_archivos_carpeta" max-width="600" persistent >
                <AgregarArchivos 
                    @cerrar="actualizar_datos_carpeta()"
                />
        </v-dialog>
    </v-sheet>
</template>
<script>
//import { mapState } from 'vuex';
import ToolbarMultiIcons from '../atomos/ToolbarMultiIcons.vue'
import ArchivosCarpetaSeleccionada from './ArchivosCarpetaSeleccionada'
import AgregarArchivos from './AgregarArchivos.vue'
import  jsPDF  from "jspdf";
import folders from '../../mixins/folders'

export default {
    mixins: [folders],
    props: {
       carpetas: {
           type: Array,
           required: true,
       }
    },
    components: {
        ToolbarMultiIcons,
        ArchivosCarpetaSeleccionada,
        AgregarArchivos, 
    },
    data(){
        return {
            busar_carpeta: "",
            nueva_carpeta: {
                nombre_carpeta: "",
            },


            valid: true,

            carpeta_filtradas: [],
            dialogo_crear_carpeta: false,
            dialogo_delete_folder: false,
            dialogo_edit_folder: false,
            dialogo_agregar_archivos_carpeta: false,

            name_folder: "",
            load_create_folder: false,

           
        }
    },
    watch: {
        carpetas(val){
            
            this.carpeta_filtradas = [...val]
        },
        busar_carpeta(val){
            val = val.toLowerCase()
            this.carpeta_filtradas = this.carpetas.filter( item => item.nombre_carpeta.toLowerCase().indexOf(val) !== -1 )
        },
    },
    created(){

        this.carpeta_filtradas = [...this.carpetas];
        this.$store.state.buscador.carpeta_seleccionada = this.carpeta_filtradas[0];

    },
    methods: {
        actualizar_datos_carpeta(){
            this.get_archivos_carpeta_seleccionada(this.$store.state.buscador.carpeta_seleccionada);
            this.dialogo_agregar_archivos_carpeta = false;
        },
        cerrar_dialogo_crear_carpeta(){
            this.dialogo_crear_carpeta = false;
        },
        color(item){
            if(typeof item.estado!='undefined' )
            console.log(item)
            return 'yellow'
        },
        async crear_carpeta(nueva_carpeta){
            console.log(nueva_carpeta);
            let response = await this.$store.dispatch('buscador/CREAR_CARPETA',nueva_carpeta);

            if(response){
                
                const carpetas = await this.$store.dispatch('buscador/GET_CARPETAS');

                console.log(carpetas);

                this.carpetas = await this.$store.dispatch('utils/SET_DATA_INDEXADOR',carpetas);
                this.carpeta_filtradas = [...this.carpetas];
                console.log(this.carpetas);
            }else{
                console.log('Mensaje de error...');
            }
        },

        async get_archivos_carpeta_seleccionada(carpeta){
            try {

                this.$store.state.buscador.carpeta_seleccionada = {...carpeta};
                console.log(carpeta);

                await this.$store.dispatch('buscador/GET_ARCHIVOS_CARPETA_SELECCIONADA',carpeta);
            } catch (error) {
                console.log(error)
            }
        },
        async editar_carpeta() {

            try {
                let carpeta = {
                    id: this.$store.state.buscador.carpeta_seleccionada.id, 
                    nombre_carpeta: this.name_folder 
                }

                const response = await this.$store.dispatch('buscador/EDITAR_CARPETA',carpeta);
                if(response){
                    this.$store.commit(
                        "exitoso",
                        "La carpeta ha sido editada de forma exitosa"
                    ); 

                    this.$store.state.buscador.carpeta_seleccionada.nombre_carpeta = this.name_folder;

                    this.name_folder = "";
                    this.dialogo_edit_folder = false;
                }else{
                    this.$store.commit(
                        "error",
                        "Ha ocurrido un error inesperado en el momento de eliminar la carpeta"
                    );
                }
            } catch (error) {
                console.log(error);
            }

        },
        async delete_folder() {
            try {
                const response = await this.$store.dispatch('buscador/ELIMINAR_CARPETA',this.$store.state.buscador.carpeta_seleccionada)

                if(response){
                    this.$store.commit(
                        "exitoso",
                        "La carpeta ha sido eliminada de forma exitosa"
                    ); 

                    await this.$store.dispatch('buscador/GET_CARPETAS');
                    
                    this.$store.state.buscador.carpeta_seleccionada = this.$store.state.buscador.carpetas[0];

                    this.dialogo_delete_folder = false;
                }else{
                    this.$store.commit(
                        "error",
                        "Ha ocurrido un error inesperado en el momento de eliminar la carpeta"
                    );
                }
            } catch (error) {
                console.log(error);
            }
        },

        download_files(files){
            this.$store.state.dialogCargando = true;
            let no_image_files = [];
          files = this.$store.state.buscador.archivos_carpeta_seleccionada
           try {
               console.log('descargar');
                console.log(files)
                var doc = new jsPDF('p', 'mm','a4');
                let altura = 0
                for (let index = 0; index < files.length; index++) {
                    if(files[index].tipo.toLowerCase()=='blob'||files[index].tipo.toLowerCase()=='jpg'||files[index].tipo.toLowerCase()=='jpeg'||files[index].tipo.toLowerCase()=='png'||files[index].tipo.toLowerCase()=='bmp'){
                        doc.addImage(
                        this.urlRespuestas(
                            files[index],
                            "https://drive.educandote.co/audidoc"
 
                            )
                        ,files[index].tipo ,0, altura,200 ,280
                        )

                        //Evita que se inserte una pagina en blanco al final 
                        if(index+1 != files.length ){
                            doc.addPage('a4','p')
                        }
                    }else{
                        no_image_files.push({page:index+1})
                    }
                    
                        
                }
                if(no_image_files.length>0){
                    let message = "";
                    for (let index = 0; index < no_image_files.length; index++) {
                        message = message + " "+ no_image_files[index].page
                        
                    }
                    this.$store.commit(
                                    "error",
                                    "Los archivos de las paginas: "+message+ " No corresponden a imagenes y no pueden ser descargadas de forma automatica, por favor descarguelos de forma manual"
                                );
                }else{
                    doc.save('Carpeta.pdf');
                    this.$store.commit(
                                    "exitoso",
                                    "Los archivos han sido descargador de forma exitosa"
                                ); 
                }   
           } catch (error) {
               console.log(error)
               this.$store.commit(
                    "error",
                    "Ha ocurrido un error inesperado en el momento de descargar una imagn "+error.message
                );
           }
           
           this.$store.state.dialogCargando = false
        },
    },
    
    
    
}
</script>