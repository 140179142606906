var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-window',{model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[_c('v-window-item',{attrs:{"value":1}},[_c('v-card',{on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.buscar()}}},[_c('v-row',[_c('v-col',{attrs:{"cols":""}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-sheet',{staticClass:"pa-4",attrs:{"elevation":"5"}},[_c('v-progress-linear',{directives:[{name:"show",rawName:"v-show",value:(_vm.load),expression:"load"}],attrs:{"color":"deep-purple accent-4","indeterminate":"","rounded":"","height":"6"}}),_c('v-toolbar',{attrs:{"rounded":"","col":"","or":"indigo darken-4","dark":""}},[_c('v-toolbar-title',{staticClass:"headline font-weight-light"},[_vm._v(" Buscadores ")]),_c('v-spacer'),_vm._t("accion")],2),_c('v-container',[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":""}},[_c('div',{staticClass:"subtitle-1 font-weight-light",domProps:{"textContent":_vm._s(
                        'Selecciona un tipo de filtro por el cual deseas buscar un documentos posteriormente, coloca su descripcion'
                      )}})])],1),_c('v-row',[_c('v-col',[_c('v-autocomplete',{attrs:{"prepend-icon":"mdi-clipboard-text-outline","rounded":"","filled":"","dense":"","return-object":"","item-text":"nombre","label":"Escoge el tipo documental","items":_vm.tipos_documentales},on:{"change":_vm.tipoFiltro},model:{value:(_vm.tipo_documental_seleccionado),callback:function ($$v) {_vm.tipo_documental_seleccionado=$$v},expression:"tipo_documental_seleccionado"}})],1),_c('v-col',{attrs:{"cols":""}},[_c('v-autocomplete',{attrs:{"rounded":"","filled":"","dense":"","prepend-icon":"mdi-clipboard-text-outline","label":"Buscar por: ","items":_vm.campos_tipo_documental,"item-text":"nombre","return-object":"","multiple":""},model:{value:(_vm.campo_seleccionado),callback:function ($$v) {_vm.campo_seleccionado=$$v},expression:"campo_seleccionado"}})],1)],1),_c('v-row',_vm._l((_vm.campo_seleccionado),function(campo,index){return _c('v-col',{key:index,attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"rounded":"","filled":"","dense":"","label":("Ingresa: " + (campo.nombre)),"append-icon":"mdi-magnify"},on:{"click:append":function($event){return _vm.buscar()}},model:{value:(campo.valor),callback:function ($$v) {_vm.$set(campo, "valor", $$v)},expression:"campo.valor"}})],1)}),1)],1),(_vm.notFound == true)?_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('div',{staticClass:"title font-weight-light",domProps:{"textContent":_vm._s(
                      'No existen coincidencias en la busqueda ' +
                        _vm.error.message
                    )}})]),_c('v-row',{staticClass:"mx-auto"},[_c('img',{staticClass:"mx-auto",attrs:{"src":require('../../assets/notFound.png'),"alt":"Datos no encontrados","contain":"","width":"300"}})])],1):_vm._e()],1)],1)],1)],1)],1)],1),_c('v-window-item',{attrs:{"value":2}},[_c('v-sheet',[_c('Toolbar',{attrs:{"activar_tool":false,"titulo":'Resultados...'},scopedSlots:_vm._u([{key:"accion_retroceder",fn:function(){return [_c('v-btn',{attrs:{"dark":"","icon":""},on:{"click":function($event){_vm.step--}}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1)]},proxy:true}])}),_c('Paginacion',{staticClass:"my-3",attrs:{"cantidad_paginas":parseInt(_vm.cantidad_paginas)},on:{"buscar":function($event){_vm.page = $event}}}),_c('v-data-table',{staticClass:"mx-10",attrs:{"headers":_vm.headers,"items":_vm.desserts,"max-width":"650","search":_vm.search,"items-per-page":250,"hide-default-footer":"","page":_vm.page},on:{"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(" Resultados ")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{attrs:{"append-outer-icon":'mdi-magnify',"label":"Buscar"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)]},proxy:true},{key:"item.id",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(item.id)+" ")]}},{key:"item.datos_archivos",fn:function(ref){
                    var item = ref.item;
return [_c('v-btn',{staticClass:"mb-2",attrs:{"color":"indigo","dark":"","icon":""},on:{"click":function($event){return _vm.revisar_archivo_seleccionado(item)}}},[_c('v-icon',[_vm._v(" mdi-folder-information-outline")])],1)]}},{key:"item.actions",fn:function(ref){
                    var item = ref.item;
return [_c('v-btn',{staticClass:"mb-2",attrs:{"color":"green","dark":"","icon":""},on:{"click":function($event){return _vm.editar_inputs(item)}}},[_c('v-icon',[_vm._v(" mdi-pencil ")])],1)]}}])})],1),_c('v-dialog',{attrs:{"fullscreen":""},model:{value:(_vm.dialogo_revisar_archivo_seleccionado),callback:function ($$v) {_vm.dialogo_revisar_archivo_seleccionado=$$v},expression:"dialogo_revisar_archivo_seleccionado"}},[_c('Card',{attrs:{"titulo":'Revisar archivo seleccionado'},on:{"activar":function($event){return _vm.cerrar_revision_archivos()}},scopedSlots:_vm._u([{key:"contenido",fn:function(){return [_c('Carpetas',{attrs:{"carpetas":_vm.$store.state.buscador.carpetas},on:{"update:carpetas":function($event){return _vm.$set(_vm.$store.state.buscador, "carpetas", $event)}}})]},proxy:true}])})],1),_c('v-dialog',{attrs:{"max-width":"600","persistent":""},model:{value:(_vm.dialogo_editar),callback:function ($$v) {_vm.dialogo_editar=$$v},expression:"dialogo_editar"}},[_c('Card',{attrs:{"load":_vm.load_editar,"titulo":'Editar informacion'},on:{"activar":function($event){_vm.dialogo_editar = false}},scopedSlots:_vm._u([{key:"contenido",fn:function(){return [_vm._l((_vm.headers_editar),function(input,index){return _c('v-row',{key:index,staticClass:"mt-2"},[_c('v-text-field',{attrs:{"prepend-icon":"mdi-book","filled":"","rounded":"","dense":"","label":input.text},model:{value:(_vm.informacion_editar[input.value]),callback:function ($$v) {_vm.$set(_vm.informacion_editar, input.value, $$v)},expression:"informacion_editar[input.value]"}})],1)}),_c('v-row',[_c('v-btn',{attrs:{"block":"","text":"","color":"green"},on:{"click":function($event){return _vm.editar_informacion_endpoint(
                  _vm.headers_editar,
                  _vm.informacion_editar
                )}}},[_vm._v(" Editar ")])],1)]},proxy:true}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }