<template>
  <v-window v-model="step">
    <v-window-item :value="1">
      <v-card v-on:keydown.enter="buscar()">
        <v-row>
          <v-col cols="">
            <v-container fluid>
              <v-sheet elevation="5" class="pa-4">
                <v-progress-linear
                  color="deep-purple accent-4"
                  indeterminate
                  rounded
                  height="6"
                  v-show="load"
                ></v-progress-linear>

                <v-toolbar rounded="" col or="indigo darken-4" dark>
                  <v-toolbar-title class="headline font-weight-light">
                    Buscadores
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                  <slot name="accion"></slot>
                </v-toolbar>
                <v-container>
                  <v-row align="center" justify="center">
                    <v-col cols="">
                      <div
                        v-text="
                          'Selecciona un tipo de filtro por el cual deseas buscar un documentos posteriormente, coloca su descripcion'
                        "
                        class="subtitle-1 font-weight-light"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-autocomplete
                        @change="tipoFiltro"
                        prepend-icon="mdi-clipboard-text-outline"
                        rounded
                        filled
                        dense
                        return-object
                        item-text="nombre"
                        v-model="tipo_documental_seleccionado"
                        label="Escoge el tipo documental"
                        :items="tipos_documentales"
                      />
                    </v-col>
                    <v-col cols="">
                      <v-autocomplete
                        rounded
                        filled
                        dense
                        prepend-icon="mdi-clipboard-text-outline"
                        v-model="campo_seleccionado"
                        label="Buscar por: "
                        :items="campos_tipo_documental"
                        item-text="nombre"
                        return-object
                        multiple
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      v-for="(campo, index) in campo_seleccionado"
                      :key="index"
                      cols="12"
                      sm="6"
                    >
                      <v-text-field
                        rounded
                        filled
                        dense
                        v-model="campo.valor"
                        :label="`Ingresa: ${campo.nombre}`"
                        append-icon="mdi-magnify"
                        @click:append="buscar()"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
                <v-container fluid v-if="notFound == true">
                  <v-row align="center" justify="center">
                    <div
                      v-text="
                        'No existen coincidencias en la busqueda ' +
                          error.message
                      "
                      class="title font-weight-light"
                    ></div>
                  </v-row>
                  <v-row class="mx-auto">
                    <!--  -->
                    <img
                      :src="require('../../assets/notFound.png')"
                      class="mx-auto"
                      alt="Datos no encontrados"
                      contain
                      width="300"
                    />
                  </v-row>
                </v-container>
              </v-sheet>
            </v-container>
          </v-col>
        </v-row>
      </v-card>
    </v-window-item>
    <v-window-item :value="2">
      <v-sheet>
        <Toolbar :activar_tool="false" :titulo="'Resultados...'">
          <template v-slot:accion_retroceder>
            <v-btn dark icon @click="step--"
              ><v-icon>mdi-arrow-left</v-icon></v-btn
            >
          </template>
        </Toolbar>
        <Paginacion
          :cantidad_paginas="parseInt(cantidad_paginas)"
          class="my-3"
          @buscar="page = $event"
        />

        <v-data-table
          :headers="headers"
          :items="desserts"
          class="mx-10"
          max-width="650"
          :search="search"
          :items-per-page="250"
          hide-default-footer
          :page.sync="page"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title> Resultados </v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-col cols="12" sm="4">
                <v-text-field
                  :append-outer-icon="'mdi-magnify'"
                  label="Buscar"
                  v-model="search"
                ></v-text-field>
              </v-col>
            </v-toolbar>
          </template>
          <template v-slot:item.id="{ item }">
            {{ item.id }}
          </template>
          <template v-slot:item.datos_archivos="{ item }">
            <v-btn
              color="indigo"
              dark
              class="mb-2"
              icon
              @click="revisar_archivo_seleccionado(item)"
            >
              <v-icon> mdi-folder-information-outline</v-icon>
            </v-btn>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn
              color="green"
              dark
              class="mb-2"
              icon
              @click="editar_inputs(item)"
            >
              <v-icon> mdi-pencil </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-sheet>
      <v-dialog v-model="dialogo_revisar_archivo_seleccionado" fullscreen>
        <Card
          :titulo="'Revisar archivo seleccionado'"
          @activar="cerrar_revision_archivos()"
        >
          <template v-slot:contenido>
            <Carpetas :carpetas.sync="$store.state.buscador.carpetas" />
          </template>
        </Card>
      </v-dialog>
      <v-dialog v-model="dialogo_editar" max-width="600" persistent>
        <Card
          :load="load_editar"
          :titulo="'Editar informacion'"
          @activar="dialogo_editar = false"
        >
          <template v-slot:contenido>
            <v-row
              class="mt-2"
              v-for="(input, index) in headers_editar"
              :key="index"
            >
              <v-text-field
                prepend-icon="mdi-book"
                filled
                rounded
                dense
                :label="input.text"
                v-model="informacion_editar[input.value]"
              ></v-text-field>
            </v-row>
            <v-row>
              <v-btn
                @click="
                  editar_informacion_endpoint(
                    headers_editar,
                    informacion_editar
                  )
                "
                block
                text
                color="green"
              >
                Editar
              </v-btn>
            </v-row>
          </template>
        </Card>
      </v-dialog>
    </v-window-item>
  </v-window>
</template>
<script>
const axios = require("axios").default;
//import RevisarArchivos from './celulas/RevisarArchivos.vue'
import { EventBus } from "../../event/event-bus";
//import NotFound from "../components/NotFound";
import folders from "../../mixins/folders";
import Carpetas from "./Carpetas.vue";
export default {
  mixins: [folders],
  components: {
    //Carousel,
    //NotFound,
    Carpetas,
  },
  data() {
    return {
      dialogo_agregar: false,

      step: 1,

      filtro: "",

      tipoFiltroSelect: "",
      resultados: [],

      dialogo_editar: false,
      informacion_editar: {},
      headers_editar: [],
      load_editar: false,

      headers: [],
      desserts: [],
      cantidad_paginas: 1,
      page: 1,
      search: "",

      items: [],

      load: false,
      notFound: false,

      //Variables agregadas por fry
      nombre_endpoint_almacenamiento: "",
      campo_seleccionado: [],
      tipo_documental_seleccionado: {},
      tipos_documentales: [],
      campos_tipo_documental: [],
      tipos_documentales_completo: [],
      parametro: "",
      archivos: [],
      error: {
        message: "",
      },
      dialogo_revisar_archivo_seleccionado: false,

      //datos_adjuntos: [],
      //formularios: [],

      coleccion: "",
      archivo_seleccionado: {},
    };
  },
  watch: {
    page(val) {
      console.log(val);
      this.buscar();
    },
    campo_seleccionado(val) {
      console.log(val);
    },
  },
  async created() {
    console.log("sss");
    this.consultar_tipos_documentales();
  },
  methods: {
    cerrar_revision_archivos() {
      console.log("s");
      this.dialogo_revisar_archivo_seleccionado = false;
      EventBus.$emit("cambiar_carpeta", 0); //Este evento se emite para ir a la caprta inicial de "revusar Archivos"
    },

    actualizar_archivos(informacion) {
      console.log(informacion);
      console.log(this.archivo_seleccionado);
      let index = this.desserts.indexOf(this.archivo_seleccionado);
      console.log(index);
      Object.assign(this.desserts[index], informacion);
      console.log(this.desserts);
    },
    async editar_informacion_endpoint(body, informacion) {
      console.log(informacion, body);
      this.load_editar = true;
      try {
        let obj = {};
        for (let index = 0; index < body.length; index++) {
          if (body[index].type.toLowerCase() == "date") {
            let field_date = `${body[index].value}_int`.toLocaleLowerCase();
            obj[field_date] = Date.parse(
              new Date(informacion[body[index].value])
            );
          }
          obj[body[index].value] = informacion[body[index].value];
        }
        console.log(obj);
        console.log(this.tipo_documental_seleccionado);
        let coleccion = this.tipo_documental_seleccionado
          .nombre_endpoint_almacenamiento;
        let response = await this.$store.dispatch("llamado_patch", {
          url:
            "https://docutest.educandote.co/types/collections/" +
            coleccion +
            "/documents/" +
            informacion.id,
          body: obj,
          tipo_header: "indexador",
        });

        let informar_edicion = await this.$store.dispatch("llamado_post", {
          url:
            "https://audidoc.educandote.co/parse/classes/prueba01Modificaciones",
          body: {
            usuario: this.$store.state.dataUser.dataUser.objectId,
            coleccion: coleccion,
            id_objeto: informacion.id,
            objeto_editado: this.desserts[informacion.index_editar],
            objeto_nuevo: obj,
          },
          tipo_header: "parse",
        });
        console.log(informar_edicion);

        Object.assign(this.desserts[informacion.index_editar], informacion);
        console.log(response);

        this.$store.commit(
          "exitoso",
          "Los campos han sido editados de forma exitosa..."
        );
      } catch (error) {
        console.log(error);
        this.$store.commit(
          "error",
          "Ha ocurrido un error inesperado al momento de editar los campos... " +
            error.message
        );
      }
      this.dialogo_editar = false;
      this.load_editar = false;
    },

    editar_inputs(item) {
      console.log(item);
      let index = this.desserts.indexOf(item);
      this.informacion_editar = { ...item, index_editar: index };
      this.dialogo_editar = true;
    },
    async revisar_archivo_seleccionado(item) {
      console.log(item);

      try {
        this.$store.state.buscador.datos_adjuntos = [];
        this.$store.state.buscador.formularios = [];
        this.$store.state.buscador.carpetas = [];
        this.$store.state.buscador.coleccion_archivo_seleccionado = this.tipo_documental_seleccionado.nombre_endpoint_almacenamiento;

        this.$store.state.buscador.archivo_seleccionado = item;

        await this.$store.dispatch("buscador/GET_CARPETAS");
        await this.$store.dispatch(
          "buscador/GET_ARCHIVOS_CARPETA_SELECCIONADA",
          this.$store.state.buscador.carpetas[0]
        );

        this.dialogo_revisar_archivo_seleccionado = true;
      } catch (error) {
        console.log(error);
      }
    },
    revisar(item) {
      console.log(item);
      let aux2;

      this.headers = [{ value: "actions", text: "Editar" }];
      this.headers_editar = [];
      Object.keys(item).forEach((key) => {
        console.log(key);
        aux2 = key.replace(/_/g, " ");

        if (
          key != "fecha_creacion_int" &&
          key != "extended_properties" &&
          key != "order" &&
          key != "fecha_documento_int" &&
          key != "workflow"
        ) {
          this.headers.push({
            value: key,
            text: aux2,
          });
        }
        if (
          key != "id" &&
          key != "fecha_creacion_int" &&
          key != "extended_properties" &&
          key != "order" &&
          key != "fecha_documento_int" &&
          key != "workflow"
        ) {
          this.headers_editar.push({
            value: key,
            text: aux2,
          });
        }
      });

      this.headers.push({
        value: "datos_archivos",
        text: "Archivos",
      });
    },

    async consultar_tipos_documentales() {
      const response = await this.$store.dispatch("llamado_get", {
        url: `https://audidoc.educandote.co/parse/classes/prueba01Sector/${this.$store.state.dataUser.dataUser.sector}`,
        tipo_header: "parse",
      });
      console.log(response);

      this.tipos_documentales = [...response.data.tipos_documentales];
    },
    tipoFiltro() {
      console.log(this.tipo_documental_seleccionado);
      this.campos_tipo_documental = [];

      this.nombre_endpoint_almacenamiento = this.tipo_documental_seleccionado.nombre_endpoint_almacenamiento;

      let aux = [];
      for (
        let index = 0;
        index < this.tipo_documental_seleccionado.parametros.length;
        index++
      ) {
        //Permite buscar fechas como si fuesen string
        let obj = {
          nombre: this.tipo_documental_seleccionado.parametros[index].name,
          nombre_endpoint: this.tipo_documental_seleccionado.parametros[index]
            .key,
          valor: "",
          tipo: "string",
        };

        aux.push(obj);
        //Si el campo es tipo date agregar una fecha
        if (
          this.tipo_documental_seleccionado.parametros[index].type == "date"
        ) {
          aux.push({
            tipo: "date",
            nombre: `Rango en: ${obj.nombre}`,
            nombre_endpoint: `${obj.nombre_endpoint}`,
            query_by: `${obj.nombre_endpoint}_int`.toLocaleLowerCase(),
            valor: "",
          });
        }
      }

      console.log(aux);

      this.campos_tipo_documental = aux;

      console.log(this.campos_tipo_documental);

      //this.campos_tipo_documental = [...this.tipo_documental_seleccionado.parametros];
    },
    buscar() {
      console.log(this.campo_seleccionado);

      this.load = true;

      //replace(/ /g, "")
      console.log(this.tipo_documental_seleccionado);
      let coleccion = this.tipo_documental_seleccionado
        .nombre_endpoint_almacenamiento;
      //coleccion = 'pruebaExpedientedecontribuyentepredial'

      //let  campo = this.campo_seleccionado.replace(/ /g, "_");
      //campo = 'Referencia_catastral'

      let busqueda = this.campo_seleccionado[0].valor;
      let query_by = this.campo_seleccionado[0].nombre_endpoint;

      if (this.campo_seleccionado[0].tipo == "date") {
        busqueda = "*";
        query_by = this.campo_seleccionado[0].nombre_endpoint;
      }

      let filter_by = ``;

      let length = this.campo_seleccionado.length;
      for (let index = 0; index < length; index++) {
        if (this.campo_seleccionado[index].tipo != "date") {
          filter_by =
            filter_by +
            `${this.campo_seleccionado[index].nombre_endpoint}:["${this.campo_seleccionado[index].valor}"]&&`;
        } else {
          let fechas = this.campo_seleccionado[index].valor.split(" ");
          let fecha_inicial = Date.parse(new Date(fechas[0]));
          let fecha_final = Date.parse(new Date(fechas[1]));
          filter_by =
            filter_by +
            `${this.campo_seleccionado[index].query_by}:[${fecha_inicial}..${fecha_final}]&&`;
        }
      }

      filter_by = filter_by.substring(0, filter_by.length - 2);

      console.log(filter_by);

      this.desserts = [];
      console.log({
        esteVe:
          "https://docutest.educandote.co/types/collections/" +
          coleccion +
          "/documents/search?q=" +
          busqueda +
          "&query_by=" +
          query_by +
          "&filter_by=" +
          filter_by +
          "&per_page=250&page=" +
          this.page,
      });
      axios
        .get(
          "https://docutest.educandote.co/types/collections/" +
            coleccion +
            "/documents/search?q=" +
            busqueda +
            "&query_by=" +
            query_by +
            "&filter_by=" +
            filter_by +
            "&per_page=250&page=" +
            this.page,
          {
            headers: {
              "Content-Type": "application/json",
              "X-TYPESENSE-API-KEY":
                "S9cLrE7dFV9T6HZ63PScpOaAIoBp7NHLFrdHoLN3RriOpyZe",
            },
          }
        )
        .then((response) => {
          console.log(response);

          if (response.data.found >= 1) {
            //this.revisar(response.data.hits[0].document);
            this.set_headers_table(
              this.tipo_documental_seleccionado.parametros
            );

            for (let index = 0; index < response.data.hits.length; index++) {
              this.desserts.push(response.data.hits[index].document);

              console.log(response.data.hits[index].document);
            }
            console.log(this.desserts);
            console.log(this.headers);
            this.step = 2;
            this.notFound = false;
            this.cantidad_paginas = parseInt(response.data.found / 250) + 1;
          } else {
            this.notFound = true;
          }
          this.load = false;
        })
        .catch((error) => {
          console.log(error);
          this.error = error;
          this.notFound = true;
          this.load = false;
        });
    },
    set_headers_table(parametros) {
      try {
        console.log(parametros);
        let headers = [
          { value: "id", text: "ID" },
          { value: "actions", text: "Editar" },
          { value: "datos_archivos", text: "Archivos" },
        ];
        let headers_editar = [];

        for (let index = 0; index < parametros.length; index++) {
          if (parametros[index].key != "workflow") {
            // El campo de workflow guarda el registro del recorrido del tramite (Requiere desarrollo para mostrarlo)
            let name = parametros[index].name;
            let key = parametros[index].key;
            let tipo = parametros[index].type;

            headers.push({
              value: key,
              text: name,
              type: tipo,
            });

            headers_editar.push({
              value: key,
              text: name,
              type: tipo,
            });
          }
        }

        //headers_editar = [...headers];

        this.headers = [...headers];
        this.headers_editar = [...headers_editar];

        console.log(headers);
        console.log(headers_editar);
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
